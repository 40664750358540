import React from "react"
import { Header } from "../components/HeaderSection"
import { Footer } from "../components/Footer"
import { AnimationWrapper } from "../components/AnimationWrapper"
import { SplitLetters } from "../components/SplitLetters"
import SEO from "../components/seo"
import { Container } from "../components/Container"
import { graphql } from "gatsby"
import Img from "gatsby-image"

const ContactUs = ({ data }) => {
  const contactUsHeaderClasses =
    "font-sans font-semibold border-b-2 border-primary text-primary text-lg py-2 mb-4 w-40"

  const contactUsWrapperClasses =
    "w-1/2 mb-10 text-center flex flex-col items-center"

  return (
    <section className="bg-body-lightest">
      <SEO
        title="Contact Us"
        description="Phone:0484606058 or +919447030856, Email:mail4cleanwater@gmail.com"
      >
        <script type="application/ld+json">
          {`{
            "@context": "https://schema.org",
            "@type": "Organization",
            "url": "https://environmentalsolutions.co.in/",
            "name": "Environmental Solutions",
            "contactPoint": [{
              "@type": "ContactPoint",
              "telephone": "0484-2606058",
              "contactType": "Office"
            }]
          }`}
        </script>
      </SEO>
      <Header />
      <section className="mb-6 md:relative md:mb-16">
        <div className="hidden inset-0 overflow-hidden md:absolute md:block">
          <Img
            className="md:-m-10"
            fluid={data.placeholderImage.childImageSharp.fluid}
          />
        </div>
        <div className="md:relative md:top-0">
          <Container>
            <div className="sm:p-10 md:pl-6/12">
              <h1 className="text-5xl font-sans font-bold text-center text-primary my-6 tracking-wide">
                <SplitLetters text="Hello, let us help you." />
              </h1>
              <AnimationWrapper
                animationType="fadeIn"
                delay="1"
                className="text-lg text-center leading-relaxed"
              >
                To know more about our services, get an estimate or for any
                other queries please get in touch. You can call us, drop an
                email or visit our office and talk to us directly.
              </AnimationWrapper>
            </div>
            <div className="flex flex-col justify-around items-center w-full pt-8 sm:px-10 md:flex-row md:flex-wrap md:items-start lg:pl-6/12">
              <AnimationWrapper
                animationType="slideToTop"
                className={contactUsWrapperClasses}
                delay="1"
              >
                <h2 className={contactUsHeaderClasses}>Call Us</h2>
                <a
                  className="block text-secondary hover:text-secondary-light"
                  href="tel:+91484-2606058"
                >
                  0484-2606058
                </a>
                <a
                  className="block text-secondary hover:text-secondary-light"
                  href="tel:+919447030856"
                >
                  +919447030856
                </a>
              </AnimationWrapper>
              <AnimationWrapper
                animationType="slideToTop"
                delay="1.1"
                className={contactUsWrapperClasses}
              >
                <h2 className={contactUsHeaderClasses}>Write To Us</h2>
                <a
                  className="block text-secondary hover:text-secondary-light"
                  href="mailto:mail4cleanwater@gmail.com"
                >
                  mail4cleanwater@gmail.com
                </a>
              </AnimationWrapper>
              <AnimationWrapper
                animationType="slideToTop"
                delay="1.2"
                className={contactUsWrapperClasses}
              >
                <h2 className={contactUsHeaderClasses}>Business Hours</h2>
                <p className="text-secondary hover:text-secondary-light">
                  Monday - Saturday
                  <br />
                  9:00 AM – 6:15 PM IST
                </p>
              </AnimationWrapper>
              <AnimationWrapper
                animationType="slideToTop"
                delay="1.3"
                className={contactUsWrapperClasses}
              >
                <h2 className={contactUsHeaderClasses}>Office Location</h2>
                <p className="text-secondary hover:text-secondary-light">
                  Environmental Solutions <br />
                  Parambayam, NH-47, <br />
                  Nedumbassery-683 585, <br />
                  Aluva, Kochi,
                  <br />
                  Kerala, India
                </p>
              </AnimationWrapper>
            </div>
          </Container>
        </div>
      </section>

      <div className="text-2xl text-primary font-semibold text-center mb-6">
        Visit our office
      </div>
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3927.5553410898783!2d76.35142991461666!3d10.135435692758382!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b080f489df3dc5d%3A0xbb595335bef6c917!2sEnvironmental%20Solutions!5e0!3m2!1sen!2suk!4v1574839573656!5m2!1sen!2suk"
        height="450"
        frameBorder="0"
        style={{ border: 0 }}
        allowFullScreen
        className="w-full"
        title="environmental-solutions-office-location"
      ></iframe>
      <Footer />
    </section>
  )
}

export default ContactUs

export const query = graphql`
  query {
    placeholderImage: file(relativePath: { eq: "contact_bg.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1280) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
